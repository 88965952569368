import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import dayjs from "dayjs";
import "./UserDetails.css";
import {
  faUser,
  faEnvelope,
  faCalendar,
  faClock,
  faMapMarkerAlt,
  faGenderless,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "antd/dist/reset.css";
import { TimePicker } from "antd"
import pagePath from "../../util/constants/pagePath";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import { login, primaryRole, setLoginRes } from "../../redux/slices/userSlice";

const UserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationnumber = useLocation();
  const { mobile_number } = locationnumber.state || {};

  const [name, setUserName] = useState("");
  const [gender, setGender] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [time, setTime] = useState("");
  const [timer, setTimer] = useState(null);
  const [date, setDate] = useState(null);
  const [location, setLocation] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [timeError, setTimeError] = useState(false);

  useEffect(() => {
    if (!mobile_number) {
      navigate(pagePath.signupV2);
    }
  }, [mobile_number, navigate]);

  if (!mobile_number) {
    return null;
  }

  const handleGoBack = () => {
    navigate(pagePath.signupV2);
  };

  const Success_signup = () => {
    navigate("/dashboard");
  };

  const formatTime = (selectedTime) => {
    if (!selectedTime) return "";

    const hours = selectedTime.getHours();
    const minutes = selectedTime.getMinutes().toString().padStart(2, "0");
    const seconds = selectedTime.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;
    return formattedTime;
  };

  const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes, seconds] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    if (parseInt(hours) > 0 && parseInt(hours) < 10) {
      hours = '0' + hours;
    }    

    return `${hours}:${minutes}:${seconds}`;
  };

  const timeChange = (newTime) => {
    setTimer(newTime);
    if (newTime) {
      setTimeError(false); // Clears error if a valid time is selected
      setTime(formatTime(new Date(newTime)));
    }
  };

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const year = inputDate.split("-")[0];

    if (/^\d{4}$/.test(year)) {
      setDate(inputDate);
    } else {
      console.error("Invalid year format. Please enter a valid year.");
    }
  };
  const handleLocationChange = async (e) => {
    const inputValue = e.target.value;

    setLocation(inputValue);

    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          inputValue
        )}`
      );

      if (response.data.length > 0) {
        const suggestedLocations = response.data.map((location) => ({
          display_name: location.display_name,
          lat: location.lat,
          lon: location.lon,
        }));
        setSuggestions(suggestedLocations);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (selectedLocation) => {
    setLocation(selectedLocation.display_name);
    setLatitude(selectedLocation.lat);
    setLongitude(selectedLocation.lon);
    setSuggestions([]);
  };

  const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!time) {
      console.error("Time of Birth is required.");
      return;
    }

    if (!date) {
      console.error("Date of Birth is required.");
      alert("Please enter your Date of Birth.");
      return;
    }

    const inputYear = parseInt(date.split("-")[0], 10);
    const currentYear = dayjs().year();
    if (inputYear < 1900 || inputYear > currentYear) {
      alert("Invalid Date of Birth. Please select a year between 1900 and today.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email_address)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    try {
      const time24hr = convertTo24Hour(time);
      const birth_dateTime = formattedDate + " " + time24hr;

      const signupRes = await standardBizApiClient.account_signup(
        name,
        email_address,
        gender,
        mobile_number,
        birth_dateTime,
        location,
        latitude,
        longitude
      );

      if (signupRes.status_code === 200) {
        dispatch(login());
        dispatch(primaryRole("user"));
        dispatch(setLoginRes(signupRes.session_id));
        Success_signup();
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const inputs = document.querySelectorAll("input, select");

    inputs.forEach((input) => {
      input.addEventListener("input", function () {
        if (this.value) {
          this.classList.add("text-entered");
        } else {
          this.classList.remove("text-entered");
        }
      });
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", function () {
          if (this.value) {
            this.classList.add("text-entered");
          } else {
            this.classList.remove("text-entered");
          }
        });
      });
    };
  }, []);

  return (
    <>
      <div className="flex bg-gradient-to-b from-[#ed8191] via-[#f8a5c2] to-[#ffe6e6] justify-center items-center min-h-screen">
        <div className="bg-white p-6 max-sm:!ml-[1rem] max-sm:!mr-[1rem] max-sm:!mt-[5rem] mt-5 mb-5 rounded-lg shadow-lg w-full max-w-md">
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Logo */}
            <div className="flex justify-center">
              <img src="/dst-logo.svg" alt="Logo" className="h-28 w-auto" />
            </div>

            <h5 className="text-xl font-rale font-semibold text-center text-gray-800">
              Just one click away...
            </h5>

            {/* Full Name */}
            <div className="formRow">
              <label
                htmlFor="name"
                className="block text-sm font-rale font-medium text-gray-700"
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className="mr-2 text-purple-500"
                />{" "}
                Full Name:{" "}
                {name === "" && <span className="text-red-500">*</span>}
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter your full name"
                className="mt-1 block font-rale w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                id="name"
                required
              />
            </div>

            {/* Gender */}
            <div className="formRow">
              <label
                htmlFor="gender"
                className="block text-sm font-medium font-rale text-gray-700"
              >
                <FontAwesomeIcon
                  icon={faGenderless}
                  className="mr-2 text-purple-500"
                />{" "}
                Gender:{" "}
                {gender === "" && <span className="text-red-500">*</span>}
              </label>
              <select
                className="mt-1 font-rale block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                id="gender"
                required
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            {/* Email */}
            <div className="formRow">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 font-rale"
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-2 text-purple-500"
                />{" "}
                Email Address:{" "}
                {email_address === "" && (
                  <span className="text-red-500">*</span>
                )}
              </label>
              <input
                type="email"
                value={email_address}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  setEmailError("");
                }}
                placeholder="Enter your email address"
                className="mt-1 font-rale block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                id="email"
                required
              />
              {emailError && (
                <p className="mt-1 text-sm text-red-500">{emailError}</p>
              )}
            </div>

            {/* Time of Birth */}
            <div className="formRow">
              <label
                htmlFor="timeOfBirth"
                className="block text-sm font-medium text-gray-700 font-rale"
              >
                <FontAwesomeIcon
                  icon={faClock}
                  className="mr-2 text-purple-500"
                />{" "}
                Time of Birth:{" "}
                {timer === null && <span className="text-red-500">*</span>}
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                 <TimePicker
                    use12Hours
                    format="hh:mm a" // AM/PM format
                    onChange={timeChange}
                    required
                    placeholder="Select Time of Birth"
                    className="mt-1 block w-full px-4 py-2 1border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
              </LocalizationProvider>
            </div>

            {/* Date of Birth */}
            <div className="formRow">
              <label
                htmlFor="dateOfBirth"
                className="block text-sm font-medium text-gray-700 font-rale"
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="mr-2 text-purple-500"
                />{" "}
                Date of Birth:{" "}
                {date === null && <span className="text-red-500">*</span>}
              </label>
              <input
                type="date"
                placeholder="Select the date"
                value={date}
                onChange={handleDateChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                id="dateOfBirth"
                min="1900-01-01" // Minimum date allowed
                max={dayjs().format("YYYY-MM-DD")} // Maximum date set to today's date
                required
              />
            </div>

            {/* Birthplace */}
            <div className="formRow relative">
              <label
                htmlFor="birthplace"
                className="block text-sm font-medium text-gray-700 font-rale"
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-2 text-purple-500"
                />{" "}
                Birthplace:{" "}
                {location === "" && <span className="text-red-500">*</span>}
              </label>
              <input
                type="text"
                placeholder="Enter birthplace"
                value={location}
                onChange={handleLocationChange}
                className="mt-1 font-rale block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                id="birthplace"
                required
              />
              {suggestions.length > 0 && (
                <div className="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-10 w-full">
                  {suggestions.map((location, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelectSuggestion(location)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    >
                      {location.display_name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Submit Button */}
            <button
              className={`w-full bg-[#FF9F3A] text-white py-2 rounded-lg shadow-lg hover:bg-indigo-700 transition duration-300 ${
                loading ? "cursor-not-allowed" : ""
              }`}
              type="submit"
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Launch your destini.ai"
              )}
            </button>
            <p className="text-center font-rale mt-4 text-gray-600">
              Or Signup with Alternative Phone No.
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
